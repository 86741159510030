.button-login {
  background: white;
  color: #00008f;
  font-weight: 700;
  border-color: #00008f;
}

.button-login:hover {
  background: #00008f;
  color: white;
  font-weight: 700;
  border-color: #00008f;
}

.text-forgot-password {
  color: #3f8ef8;
  font-weight: 600;
}

.remove-margin-botton {
  margin-bottom: 0px !important;
}


.header-font {
  font-size: 25px;
  font-weight: 400;
}

.loginImage {
  background-image: url("../../Asserts/images/background/bg_login.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
}

.version{
  font-size: 18px;
  position: relative;
  top: 45%;
  left: -45%;
  color: #F9752E;
  font-weight: 600;
  margin-left: 3%;
}

/* .loginLogo {
  background-image: url("../../../assets/img/brand/images.png");
} */
/* 
.input {
  border-radius: 20px;
  padding: 5px;
}

.input:focus {
  border-color: #f9762e;
  outline: none !important;
  border:2px solid #f9762e;
  box-shadow: 0 0 5px #719ECE;
} */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  background-color: #ff5e43;
  padding: 5rem;
  opacity: .8;
  border-radius: 1rem;
}