html,
body,
#root {
  margin: 0px;
  height: 100%;
}

.header-menu-sidebar {
  font-weight: 700;
  font-size: 15px;
  /*background: #0005e6 !important;*/
}



.responsive-pdf-icon {
  max-width: 30px;
  max-height: 30px;
  height: auto;
  cursor: pointer;

}


.header-size {
  height: 60px !important;

}

.body-app-margin {
  margin-top: 10px !important;
}

.head-menu {
  font-weight: bold;
  /*background: #20a8d8 !important;*/
}

.sub-menu {
  left: 5px;
}

/*.head-menu:active{*/
/*  background: red !important;*/
/*}*/

.vertical {
  /*display: block;*/
  border-left: 6px solid #0005e6;
  height: 15%;
  position: absolute;
  /*margin-right: 20px !important;*/
}

.vertical-1 {
  /*display: block;*/
  border-left: 6px solid #0005e6;
  height: 10%;
  position: absolute;
  /*margin-right: 20px !important;*/
}

.vertical-sub {
  /*display: block;*/
  border-left: 6px solid #0005e6;
  height: 8%;
  position: absolute;
  /*margin-right: 20px !important;*/
}

.responsive-dashboard-card {
  max-width: 50px;
  max-height: 50px;
  height: auto;

}

.sms-card-color {
  color: #3b5998;
}

.email-card-color {
  color: #00acec;
}

.pdf-card-color {
  color: #d34836;
}

.letter-card-color {
  color: #4975b4;
}

.activity-card-color {
  color: #ffc107;
}

.callout-resend {
  border-left-color: #00008f;
}

.break-line-bottom {
  /*border-bottom : #73818f 1px solid;*/
  /*opacity: 80%;*/
  margin-top: 10px;
}

.axa-color {
  color: #ff5e43 !important;
}

.cursor-pointers {
  cursor: pointer;
}


.text-value-custom {
  font-size: 1.2vw;
  font-weight: 600;
}

.text-value-white {
  font-size: 1.2vw;
  font-weight: 600;
  color: #fcfcfc;
}


.border-color-axa {
  border-left-color: #00008f !important;
}

.remove-padding-custom {
  padding: 0px;
}

.margin-top-10 {
  margin-top: 10px;
}

.w100 {
  width: 100%;
}

.form-control{
  height: 90% !important;
}
.form-control:disabled{
    color: #f9762e;
  }